import "../../styles/PropertyCard.css";
import { MdOutlinePinDrop, MdOutlineRemoveRedEye } from "react-icons/md";
import house from "../../images/etienne-beauregard-riverin-B0aCvAVSX8E-unsplash.jpg";
import { FaBath, FaBed, FaToilet } from "react-icons/fa";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const PropertyCard = ({
  name,
  image,
  tokenBUrn,
  bedroom,
  bathroom,
  toilet,
  price,
}) => {
  const navigate = useNavigate();
  const [ref, inView] = useInView();

  return (
    <>
      <motion.div
        ref={ref}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
        initial={{ opacity: 0, y: 100 }}
        transition={{ duration: 1, ease: "easeIn", type: "spring" }}
      >
        <div
          className="propertyCard"
          onClick={() => navigate("/City Pride Residence")}
        >
          <div className="image">
            <img src={image} alt="" />
            <div className="overlay">
              <MdOutlineRemoveRedEye />
            </div>
          </div>
          <div className="cardContent">
            <div className="head">
              <h3>{name}</h3>
              <h2>{price}</h2>
            </div>
            <p>
              <MdOutlinePinDrop /> Rumuodamaya, Port Harcourt
            </p>
            {/* <div className="propertyStat">
              <div className="propertyStatCard">
                <FaBed />{bedroom} Bedroom
              </div>
              <div className="propertyStatCard">
                <FaBath />{bathroom} Bathroom
              </div>
              <div className="propertyStatCard">
                <FaToilet />{toilet} Toilets
              </div>
            </div> */}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default PropertyCard;
