import "../styles/Footer.css";
import { MdOutlineEmail, MdOutlineLocalPhone } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <footer>
        <p>&copy; All Rights Reserved. Lambabros Deals Limited</p>
        <div className="contact">
          {/* <p><MdOutlineEmail /> lambatoken@gmail.com</p> */}
          <p>
            <MdOutlineLocalPhone /> +2347026615805
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
