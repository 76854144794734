import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import EstateInfo from "./pages/EstateInfo";

function App() {
  return <>
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/:name" element={<EstateInfo/>}/>
      </Routes>
    </Router>
  </>
}

export default App;
