import BecomeRealtor from "../components/BecomeRealtor"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import About from "../components/landing page/about"
import Hero from "../components/landing page/hero"
import Properties from "../components/landing page/properties"
import "../styles/Landing.css"
import { FaArrowUp } from "react-icons/fa6";
import { useInView } from "react-intersection-observer";
import {  motion } from "framer-motion";
import { useEffect, useRef, useState } from "react"

const Home = () => {
    const [showArrow, setShowArrow] = useState(false);
     const scrollToTopRef = useRef();

    useEffect(() => {
        const handleScroll = () => {
          // Set showArrow to true when user scrolls down, false when at the top
          setShowArrow(window.scrollY > 200);
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          // Remove the event listener when the component is unmounted
          window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    

    return<>
        <Navbar/>
        <Hero/>
        <About/>
        <Properties/>
        <BecomeRealtor/>
        <Footer/>
        {showArrow && (
            <div className="arrowUp" onClick={handleScrollToTop} ref={scrollToTopRef}>
            <FaArrowUp />
            </div>
        )}
    </>

}

export default Home