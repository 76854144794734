import { NavLink } from "react-router-dom";
import "../styles/Navbar.css";
import { RiMenu3Line } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { useState } from "react";
import logo from "../images/lbtlogo.png";

const Navbar = () => {
  const activeStyle = {
    color: "#DF1818",
  };

  const [nav, setNav] = useState(false);

  return (
    <>
      <nav>
        <img src={logo} alt="" />
        <div className="menu">
          <a href="/">Home</a>
          <a href="/#about">About</a>
          <a href="/#properties">Properties</a>
          <button>Sign Up</button>
        </div>
        <div className="menuIcon">
          {nav ? (
            <IoMdClose onClick={() => setNav(!nav)} />
          ) : (
            <RiMenu3Line onClick={() => setNav(!nav)} />
          )}
        </div>
      </nav>
      <div className={nav ? "responsiveNav" : "hide"}>
        <a href="/">Home</a>
        <a href="/#about">About</a>
        <a href="/#properties">Properties</a>
        <button>Sign Up</button>
      </div>
    </>
  );
};

export default Navbar;
