import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/EstateInfo.css";
import video from "../images/running_-_27539 (1080p).mp4";
import { GrDocumentText } from "react-icons/gr";
import {
  MdFence,
  MdHomeRepairService,
  MdOutlinePinDrop,
  MdOutlineSecurity,
  MdPower,
} from "react-icons/md";
import { IoCarOutline } from "react-icons/io5";
import Bed3WithMaids1 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0005.png";
import Bed3WithMaids2 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0004.png";
import Bed3WithMaids3 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0003.png";
import Bed3WithMaids4 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0002.png";
import BedON3floors1 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0010.png";
import BedON3floors2 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0009.png";
import BedON3floors3 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0008.png";
import BedON3floors4 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0007.png";
import BedON3floors5 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0006.png";
import Bed4duplex1 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0014.png";
import Bed4duplex2 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0013.png";
import Bed4duplex3 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0015.png";
import Bed4duplex4 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0012.jpg";
import Bed4duplex5 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0011.png";
import terrace2bedduplex1 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0019.png";
import terrace2bedduplex2 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0018.png";
import terrace2bedduplex3 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0017.png";
import terrace2bedduplex4 from "../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0016.png";
import { FaBath, FaBed, FaRoad, FaToilet, FaWater } from "react-icons/fa";
import { FaKitchenSet, FaPersonSwimming } from "react-icons/fa6";
import { BiCloset } from "react-icons/bi";
import { TbSmartHome } from "react-icons/tb";
import { useState } from "react";

const EstateInfo = () => {
  const [Bed4, setBed4] = useState(Bed4duplex1);
  const [terrace2, setTerrace2] = useState(terrace2bedduplex1);
  const [bed3WMaids, setBed3WMaids] = useState(Bed3WithMaids1);
  const [bed3floord, setBed3Floors] = useState(BedON3floors1);
  return (
    <>
      <Navbar />
      <section className="heroVideo">
        <iframe
          // width="560"
          // height="315"
          src="https://www.youtube.com/embed/0j8b_Z2M9IU?si=1rVwvjAALtc71qoy"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          autoplay
        ></iframe>
        {/* <div className="overlay">
                <h1>CITY PRIDE RESIDENCE</h1>
            </div> */}
      </section>

      <section className="estateInfo">
        <div className="topSection">
          <div className="duplexInfo">
            <h2>OWN A DUPLEX</h2>
            <h2 className="tag">INITIAL DEPOSIT</h2>
            <h1>3.5M</h1>
          </div>
          <div className="duplexInfo2">
            <p className="document">
              <GrDocumentText />{" "}
              <span>
                APPROVED <br />
                CERTIFICATE OF
                <br />
                OCCUPACY
              </span>
            </p>
            <p className="payment">
              <span>FLEXIBLE PAYMENT PLAN</span>
              SPREAD OVER 18 MONTHS
            </p>
          </div>
        </div>
        <p className="estateFeatures">
          <span>CONSTANT</span> POWER SUPPLY / <span>24 HOURS SECURITY </span>{" "}
          CONTROLLED ACCESS & MORE
        </p>
      </section>

      <section className="buildings">
        {/* <h2>Buildings</h2> */}

        <div className="buildingsWrapper">
          <div className="buildingsCard">
          <img src={Bed4} alt="" />
              <div className="smallImages">
                <img
                  src={Bed4duplex2}
                  alt=""
                  onClick={() => setBed4(Bed4duplex2)}
                />
                <img
                  src={Bed4duplex3}
                  alt=""
                  onClick={() => setBed4(Bed4duplex3)}
                />
                <img
                  src={Bed4duplex4}
                  alt=""
                  onClick={() => setBed4(Bed4duplex4)}
                />
                <img
                  src={Bed4duplex5}
                  alt=""
                  onClick={() => setBed4(Bed4duplex5)}
                />
              </div>
            <div className="content">
              <h2>Luxurious Four-Bedroom Duplex with Boys' Quarter</h2>
              <h3>
                72M, &nbsp;
                <span className="price">
                  Token Burn Rate: 3,400LBT - 8,200LTB
                </span>
              </h3>
              <p>
                Welcome to a harmonious blend of sophistication and
                functionality in this exquisite four-bedroom duplex with a boys'
                quarter in a serene neighborhood. Boasting modern design
                elements and practical features, this residence offers an
                exceptional living experience with an initial deposit of N3.5
                million while you spread the balance across 18 months.
                <ul>
                  <p style={{ fontWeight: 600, marginTop: "20px" }}>
                    Key Feautres:
                  </p>
                  <li>
                    <span>Contemporary Design: </span>
                    Step into a stylishly designed living space that exudes
                    elegance and comfort, featuring sleek finishes and an open
                    layout for effortless entertaining.
                  </li>
                  <li>
                    <span>Modern Kitchen: </span>
                    The gourmet kitchen is a chef's delight, equipped with
                    high-end appliances, sleek countertops, and abundant storage
                    space, making meal preparation a pleasure.
                  </li>
                  <li>
                    <span>Spacious Bedrooms: </span>
                    Retreat to one of four generously sized bedrooms, including
                    a luxurious master suite with a private ensuite bathroom,
                    providing a serene sanctuary for relaxation.
                  </li>
                  <li>
                    <span>Versatile Boys' Quarter: </span>
                    The separate boys' quarter offers versatility, serving as
                    guest accommodation, a home office, or a cozy retreat for
                    extended family members, ensuring privacy and convenience.
                  </li>
                </ul>
              </p>
              <p className="location">
                <MdOutlinePinDrop /> Conveniently located in a desirable
                neighborhood, a minute drive from the new road roundabout where
                we have Kilimanjaro and market square shopping mall. This duplex
                is located in Rumuodomaya which provides easy access to schools,
                shopping centers, restaurants, and other amenities, ensuring a
                vibrant and comfortable lifestyle for you and your family.
              </p>
              <div className="houseFeatures">
                <p>
                  <FaBath /> 6 Bathrooms
                </p>
                <p>
                  <FaToilet /> 6 Toilet
                </p>
                <p>
                  <FaBed /> 5 Bedrooms
                </p>
                <p>
                  <IoCarOutline /> Parking Space for multiple vehicles
                </p>
                <p>
                  <FaRoad /> Paved access roads
                </p>
                <p>
                  <BiCloset /> Built-in wardrobes and closet
                </p>
                <p>
                  <FaKitchenSet /> Classic furnished kitchen
                </p>
                <p>
                  <TbSmartHome /> Smart home with automations
                </p>
                <p>
                  <MdPower /> Constant and central power supply
                </p>
                <p>
                  <MdFence /> Perimeter fence
                </p>
                <p>
                  <FaWater /> Central water
                </p>
                <p>
                  <FaPersonSwimming /> Garden and swimming pool
                </p>
                <p>
                  <MdOutlineSecurity /> 24hrs security controlled access for
                  peace of mind
                </p>
                <p>
                  <MdHomeRepairService /> Fully serviced estate.
                </p>
              </div>
            </div>
          </div>
          <div className="buildingsCard reverse">
            <div className="image">
              <img src={Bed4} alt="" />
              <div className="smallImages">
                <img
                  src={Bed4duplex2}
                  alt=""
                  onClick={() => setBed4(Bed4duplex2)}
                />
                <img
                  src={Bed4duplex3}
                  alt=""
                  onClick={() => setBed4(Bed4duplex3)}
                />
                <img
                  src={Bed4duplex4}
                  alt=""
                  onClick={() => setBed4(Bed4duplex4)}
                />
                <img
                  src={Bed4duplex5}
                  alt=""
                  onClick={() => setBed4(Bed4duplex5)}
                />
              </div>
            </div>
            <div className="content">
              <h2>Luxurious Four-Bedroom Duplex</h2>
              <h3>
                68M, &nbsp;
                <span className="price">
                  Token Burn Rate: 3,100LBT - 7,600LBT
                </span>
              </h3>
              <p>
                Welcome to your dream home! Nestled in a serene neighborhood,
                this stunning four-bedroom duplex offers the perfect blend of
                comfort, style, and functionality. You can make as low as
                N3.5million initial deposit while you spread the balance across
                18 months.
                <ul>
                  <p style={{ fontWeight: 600, marginTop: "20px" }}>
                    Key Feautres:
                  </p>
                  <li>
                    <span>Contemporary Design: </span>
                    Step into a stylishly designed living space that exudes
                    elegance and comfort, featuring sleek finishes and an open
                    layout for effortless entertaining.
                  </li>
                  <li>
                    <span>Modern Kitchen: </span>
                    The gourmet kitchen is a chef's delight, equipped with
                    high-end appliances, sleek countertops, and abundant storage
                    space, making meal preparation a pleasure.
                  </li>
                  <li>
                    <span>Spacious Bedrooms: </span>
                    Retreat to one of four generously sized bedrooms, including
                    a luxurious master suite with a private ensuite bathroom,
                    providing a serene sanctuary for relaxation.
                  </li>
                </ul>
              </p>
              <p className="location">
                <MdOutlinePinDrop /> Conveniently located in a desirable
                neighborhood, a minute drive from the new road roundabout where
                we have Kilimanjaro and market square shopping mall. This duplex
                is located in Rumuodomaya which provides easy access to schools,
                shopping centers, restaurants, and other amenities, ensuring a
                vibrant and comfortable lifestyle for you and your family.
              </p>
              <div className="houseFeatures">
                <p>
                  <FaBath /> 5 Bathrooms
                </p>
                <p>
                  <FaToilet /> 5 Toilet
                </p>
                <p>
                  <FaBed /> 4 Bedrooms
                </p>
                <p>
                  <IoCarOutline /> Parking Space for multiple vehicles
                </p>
                <p>
                  <FaRoad /> Paved access roads
                </p>
                <p>
                  <BiCloset /> Built-in wardrobes and closet
                </p>
                <p>
                  <FaKitchenSet /> Classic furnished kitchen
                </p>
                <p>
                  <TbSmartHome /> Smart home with automations
                </p>
                <p>
                  <MdPower /> Constant and central power supply
                </p>
                <p>
                  <MdFence /> Perimeter fence
                </p>
                <p>
                  <FaWater /> Central water
                </p>
                <p>
                  <FaPersonSwimming /> Garden and swimming pool
                </p>
                <p>
                  <MdOutlineSecurity /> 24hrs security controlled access for
                  peace of mind
                </p>
                <p>
                  <MdHomeRepairService /> Fully serviced estate.
                </p>
              </div>
            </div>
          </div>
          <div className="buildingsCard">
            <div className="image">
              <img src={bed3WMaids} alt="" />
              <div className="smallImages">
                <img
                  src={Bed3WithMaids1}
                  alt=""
                  onClick={() => setBed3WMaids(Bed3WithMaids1)}
                />
                <img
                  src={Bed3WithMaids2}
                  alt=""
                  onClick={() => setBed3WMaids(Bed3WithMaids2)}
                />
                <img
                  src={Bed3WithMaids3}
                  alt=""
                  onClick={() => setBed3WMaids(Bed3WithMaids3)}
                />
                <img
                  src={Bed3WithMaids4}
                  alt=""
                  onClick={() => setBed3WMaids(Bed3WithMaids4)}
                />
              </div>
            </div>
            <div className="content">
              <h2>Luxurious Three-Bedroom Duplex + Maid room</h2>
              <h3>
                69M, &nbsp;
                <span className="price">
                  Token Burn Rate: 3,000LBT - 7,400LBT
                </span>
              </h3>
              <p>
                Discover the perfect blend of comfort and style in this inviting
                three-bedroom duplex with a maid room. Ideal for families or
                individuals seeking a modern living space, this residence offers
                convenience and sophistication in equal measure. You can make as
                low as N3.5million initial deposit while you spread the balance
                across 18 months.
                <ul>
                  <p style={{ fontWeight: 600, marginTop: "20px" }}>
                    Key Feautres:
                  </p>
                  <li>
                    <span>Contemporary Design: </span>
                    Step into a stylishly designed living space that exudes
                    elegance and comfort, featuring sleek finishes and an open
                    layout for effortless entertaining.
                  </li>
                  <li>
                    <span>Modern Kitchen: </span>
                    The gourmet kitchen is a chef's delight, equipped with
                    high-end appliances, sleek countertops, and abundant storage
                    space, making meal preparation a pleasure.
                  </li>
                  <li>
                    <span>Spacious Bedrooms: </span>
                    Retreat to one of four generously sized bedrooms, including
                    a luxurious master suite with a private ensuite bathroom,
                    providing a serene sanctuary for relaxation.
                  </li>
                  <li>
                    <span>Convenient Maid's Room: </span>
                    The separate maid's room offers flexibility and convenience,
                    ideal for accommodating household staff or for use as an
                    additional bedroom or home office space. This maid’s room is
                    on the ground floor which allows you to lockup the first
                    floor when you are not around.
                  </li>
                </ul>
              </p>
              <p className="location">
                <MdOutlinePinDrop /> Conveniently located in a desirable
                neighborhood, a minute drive from the new road roundabout where
                we have Kilimanjaro and market square shopping mall. This duplex
                is located in Rumuodomaya which provides easy access to schools,
                shopping centers, restaurants, and other amenities, ensuring a
                vibrant and comfortable lifestyle for you and your family.
              </p>
              <div className="houseFeatures">
                <p>
                  <FaBath /> 4 Bathrooms
                </p>
                <p>
                  <FaToilet /> 4 Toilet
                </p>
                <p>
                  <FaBed /> 4 Bedrooms
                </p>
                <p>
                  <IoCarOutline /> Parking Space for multiple vehicles
                </p>
                <p>
                  <FaRoad /> Paved access roads
                </p>
                <p>
                  <BiCloset /> Built-in wardrobes and closet
                </p>
                <p>
                  <FaKitchenSet /> Classic furnished kitchen
                </p>
                <p>
                  <TbSmartHome /> Smart home with automations
                </p>
                <p>
                  <MdPower /> Constant and central power supply
                </p>
                <p>
                  <MdFence /> Perimeter fence
                </p>
                <p>
                  <FaWater /> Central water
                </p>
                <p>
                  <FaPersonSwimming /> Garden and swimming pool
                </p>
                <p>
                  <MdOutlineSecurity /> 24hrs security controlled access for
                  peace of mind
                </p>
                <p>
                  <MdHomeRepairService /> Fully serviced estate.
                </p>
              </div>
            </div>
          </div>
          <div className="buildingsCard reverse">
            <div className="image">
              <img src={bed3floord} alt="" />
              <div className="smallImages">
                <img
                  src={BedON3floors2}
                  alt=""
                  onClick={() => setBed3Floors(BedON3floors2)}
                />
                <img
                  src={BedON3floors3}
                  alt=""
                  onClick={() => setBed3Floors(BedON3floors3)}
                />
                <img
                  src={BedON3floors4}
                  alt=""
                  onClick={() => setBed3Floors(BedON3floors4)}
                />
                <img
                  src={BedON3floors5}
                  alt=""
                  onClick={() => setBed3Floors(BedON3floors5)}
                />
              </div>
            </div>
            <div className="content">
              <h2>Modern Three-Bedroom Tri-Level Duplex</h2>
              <h3>
                56M, &nbsp;
                <span className="price">
                  Token Burn Rate: 2,200LBT - 6,600LBT
                </span>
              </h3>
              <p>
                Experience contemporary living at its finest in this stylish
                three-bedroom duplex spanning three floors. Designed with
                sophistication and functionality in mind, this residence offers
                an exceptional blend of space and comfort. You can make as low
                as N3.5million initial deposit while you spread the balance
                across 18 months
                <ul>
                  <p style={{ fontWeight: 600, marginTop: "20px" }}>
                    Key Feautres:
                  </p>
                  <li>
                    <span>Tri-Level Design: </span>
                    Enjoy the luxury of space across three well-defined levels,
                    offering privacy and versatility for living, working, and
                    entertaining.
                  </li>
                  <li>
                    <span>Sleek Interior: </span>
                    Step into a chic living space adorned with modern finishes
                    and large windows, creating an airy ambiance and providing
                    picturesque views of the surroundings.
                  </li>
                  <li>
                    <span>Gourmet Kitchen: </span>
                    The well-equipped kitchen boasts premium appliances, ample
                    storage, and sleek countertops, making it a culinary haven
                    for cooking enthusiasts and social gatherings.
                  </li>
                  <li>
                    <span>Spacious Bedrooms: </span>
                    Retreat to one of three generously sized bedrooms, including
                    a luxurious master suite occupying its own floor, complete
                    with a private ensuite bathroom and walk-in closet.
                  </li>
                </ul>
              </p>
              <p className="location">
                <MdOutlinePinDrop /> Conveniently located in a desirable
                neighborhood, a minute drive from the new road roundabout where
                we have Kilimanjaro and market square shopping mall. This duplex
                is located in Rumuodomaya which provides easy access to schools,
                shopping centers, restaurants, and other amenities, ensuring a
                vibrant and comfortable lifestyle for you and your family.
              </p>
              <div className="houseFeatures">
                <p>
                  <FaBath /> 4 Bathrooms
                </p>
                <p>
                  <FaToilet /> 4 Toilet
                </p>
                <p>
                  <FaBed /> 4 Bedrooms
                </p>
                <p>
                  <IoCarOutline /> Parking Space for multiple vehicles
                </p>
                <p>
                  <FaRoad /> Paved access roads
                </p>
                <p>
                  <BiCloset /> Built-in wardrobes and closet
                </p>
                <p>
                  <FaKitchenSet /> Classic furnished kitchen
                </p>
                <p>
                  <TbSmartHome /> Smart home with automations
                </p>
                <p>
                  <MdPower /> Constant and central power supply
                </p>
                <p>
                  <MdFence /> Perimeter fence
                </p>
                <p>
                  <FaWater /> Central water
                </p>
                <p>
                  <FaPersonSwimming /> Garden and swimming pool
                </p>
                <p>
                  <MdOutlineSecurity /> 24hrs security controlled access for
                  peace of mind
                </p>
                <p>
                  <MdHomeRepairService /> Fully serviced estate.
                </p>
              </div>
            </div>
          </div>
          <div className="buildingsCard">
            <div className="image">
              <img src={terrace2} alt="" />
              <div className="smallImages">
                <img
                  src={terrace2bedduplex1}
                  alt=""
                  onClick={() => setTerrace2(terrace2bedduplex1)}
                />
                <img
                  src={terrace2bedduplex2}
                  alt=""
                  onClick={() => setTerrace2(terrace2bedduplex2)}
                />
                <img
                  src={terrace2bedduplex3}
                  alt=""
                  onClick={() => setTerrace2(terrace2bedduplex3)}
                />
                <img
                  src={terrace2bedduplex4}
                  alt=""
                  onClick={() => setTerrace2(terrace2bedduplex4)}
                />
              </div>
            </div>
            <div className="content">
              <h2>Charming Two-Bedroom Duplex</h2>
              <h3>
                45M &nbsp;
                <span className="price">
                  Token Burn Rate: 3,000LBT - 7,400LBT
                </span>
              </h3>
              <p>
                Welcome to your cozy retreat! This charming two-bedroom duplex
                offers a perfect blend of comfort and convenience, ideal for
                those seeking a cozy yet stylish living space. You can make as
                low as 3.5million initial deposit while you spread the balance
                across 18 months.
                <ul>
                  <p style={{ fontWeight: 600, marginTop: "20px" }}>
                    Key Feautres:
                  </p>
                  <li>
                    <span>Sleek Interior: </span>
                    Step into a chic living space adorned with modern finishes
                    and large windows, creating an airy ambiance and providing
                    picturesque views of the surroundings.
                  </li>
                  <li>
                    <span>Gourmet Kitchen: </span>
                    The well-equipped kitchen boasts premium appliances, ample
                    storage, and sleek countertops, making it a culinary haven
                    for cooking enthusiasts and social gatherings.
                  </li>
                  <li>
                    <span>Spacious Bedrooms: </span>
                    Retreat to one of three generously sized bedrooms, including
                    a luxurious master suite occupying its own floor, complete
                    with a private ensuite bathroom and walk-in closet.
                  </li>
                </ul>
              </p>
              <p className="location">
                <MdOutlinePinDrop /> Conveniently located in a desirable
                neighborhood, a minute drive from the new road roundabout where
                we have Kilimanjaro and market square shopping mall. This duplex
                is located in Rumuodomaya which provides easy access to schools,
                shopping centers, restaurants, and other amenities, ensuring a
                vibrant and comfortable lifestyle for you and your family.
              </p>
              <div className="houseFeatures">
                <p>
                  <FaBath /> 4 Bathrooms
                </p>
                <p>
                  <FaToilet /> 4 Toilet
                </p>
                <p>
                  <FaBed /> 4 Bedrooms
                </p>
                <p>
                  <IoCarOutline /> Parking Space for multiple vehicles
                </p>
                <p>
                  <FaRoad /> Paved access roads
                </p>
                <p>
                  <BiCloset /> Built-in wardrobes and closet
                </p>
                <p>
                  <FaKitchenSet /> Classic furnished kitchen
                </p>
                <p>
                  <TbSmartHome /> Smart home with automations
                </p>
                <p>
                  <MdPower /> Constant and central power supply
                </p>
                <p>
                  <MdFence /> Perimeter fence
                </p>
                <p>
                  <FaWater /> Central water
                </p>
                <p>
                  <FaPersonSwimming /> Garden and swimming pool
                </p>
                <p>
                  <MdOutlineSecurity /> 24hrs security controlled access for
                  peace of mind
                </p>
                <p>
                  <MdHomeRepairService /> Fully serviced estate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contactProperty">
        <h2>
          Experience the epitome of modern living. Schedule a viewing today to
          make any of this duplex your new home!
        </h2>
        <p>
          Call +2347026615805 or Whatsapp <a href="	https://wa.me/+2347026615805?text=I'm%20inquiring%20about%20the%20apartment%20listing">here</a>
        </p>
      </section>

      <Footer />
    </>
  );
};

export default EstateInfo;
