import PropertyCard from "../cards/propertyCard";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Bed3WithMaids1 from "../../images/City Pride Residence/RUMUODOMAYA FLOOR PLANS COMBINED_page-0005.png";

const Properties = () => {
  const [ref, inView] = useInView();

  return (
    <>
      <motion.div
        ref={ref}
        animate={inView ? { opacity: 1 } : { opacity: 0 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 1, ease: "easeIn", type: "keyframes" }}
      >
        <div className="properties" id="properties">
          <div className="heading">
            <h1>Properties Display</h1>
            <div className="filterButtons">
              <button>Terraced Duplex</button>
              <button>Semi Detached</button>
              <button>Semi Detached</button>
              <button>Semi Detached</button>
            </div>
          </div>
          <div className="propertyCardWrapper">
            <div className="card">
              <PropertyCard
                name="City Pride Residence"
                image={Bed3WithMaids1}
                price="45M - 72M"
              />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Properties;
