

const BecomeRealtor = () => {

    return<>
        <div className="becomeRealtor">
            <h1>Do you want to become a realtor with us?</h1>
            <p>Become a realtor with us to earn commissions and our digital assets for free?</p>
            <button>Sign Up Here</button>
        </div>
    </>

}

export default BecomeRealtor