import { motion } from "framer-motion";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const About = () => {
  const [ref, inView] = useInView();

  return (
    <>
      <motion.div
        ref={ref}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 0 }}
        initial={{ opacity: 0, y: 100 }}
        transition={{ duration: 1, ease: "easeIn", type: "keyframes" }}
      >
        <div className="about" id="about">
          <div className="heading">
            <h1>
              About <span>Lambatoken</span> Real Estate Tokenization
            </h1>
            <p>
              We understand that the inflation rate is affecting both developers
              and buyers of new homes, so we decided to create an alternative
              market that doesn't depend on the rate of development or inflation
              of any geographical location but depends on the law of scarcity,
              supply, and demand.{" "}
            </p>
          </div>

          <div className="aboutContent">
            <div className="leftContent">
              <div className="aboutstatCard">
                <h2>
                  <CountUp end={4500000} duration={10} delay={2} />
                  LBT
                </h2>
                <p>Token Total Supply</p>
              </div>
              <div className="aboutstatCard">
                <h2 className="white">
                  <CountUp end={400000} duration={10} delay={2} />
                  LBT
                </h2>
                <p>Token burn for real estate tokenization</p>
              </div>
              <div className="aboutstatCard">
                <h2>
                  <CountUp end={600000} duration={10} delay={2} />
                  LBT
                </h2>
                <p>Allotted token for real estate tokenization</p>
              </div>
              <div className="aboutstatCard">
                <h2>
                  <CountUp end={50000} duration={10} delay={2} />
                  %+
                </h2>
                <p>Token growth from inception</p>
              </div>
            </div>
            <div className="rightContent">
              <p>
                <span className="circle"></span>
                With our innovation, our tokenized properties have the potential
                to be free for a buyer when the value of the digital asset
                assigned to the buyer matches up with the amount of the property
                bought.
              </p>
              <p>
                <span className="circle"></span>
                For every Lambatoken given to a property buyer, tenant, and our
                realtors, 2 of it is burnt out of the market, generating value
                for holders while providing more liquidity for the digital asset
                and decreasing the total supply, helping to combat inflation.
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default About;
