import { useInView } from "react-intersection-observer";
import {  motion } from "framer-motion";


const Hero = () => {
    
    const [ref, inView] = useInView();

    return<>

        <div className="hero" id="home">
            <div className="overlay">
                <motion.div 
                    ref={ref}
                    animate={inView ? {opacity: 1} : {opacity: 0}}
                    initial={{opacity: 0,}}
                    transition={{duration: 1, ease: "easeIn", type: "keyframes"}}
                >
                    <h1>Creating a tradable global secondary value for local real estate markets</h1>
                    <p className="desc">Get our free digital asset that is very limited in supply when you buy/rent our tokenized properties. Enjoy growth on your liquid digital asset while enjoying your new home.</p>
                    <div className="stats">
                        <div className="statWrapper">
                            <div className="statCard">
                                <h2>5</h2>
                                <p>No. of Individual properties tokenized</p>
                            </div>
                            <div className="statCard">
                                <h2>2</h2>
                                <p>No. of Estate Tokenized</p>
                            </div>
                            <div className="statCard">
                                <h2>$2.2 million</h2>
                                <p>Total Value of Estate Tokenized</p>
                            </div>
                        </div>
                    </div>
                    <button>Sign Up</button>
                </motion.div>
            </div>
        </div>

    </>

}

export default Hero